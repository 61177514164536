export const formatText = function (text) {
  if (!text) return text; // Handle undefined or empty input

  // Remove any non-digit characters (if needed)
  const cleanedText = text.replace(/\D/g, '');

  if (/^04\d{8}$/.test(cleanedText)) {
      // Mobile: 04 XX XXX XXX
      return cleanedText.replace(/(04)(\d{2})(\d{3})(\d{3})/, '$1 $2 $3 $4');
  } else if (/^0[2-9]\d{8}$/.test(cleanedText)) {
      // Landline: 0X XXXX XXXX
      return cleanedText.replace(/(0\d)(\d{4})(\d{4})/, '$1 $2 $3');
  } else if (/^13\d{6}$/.test(cleanedText)) {
      // Short fancy 13-hundred: 13 XXX XXX
      return cleanedText.replace(/(13)(\d{3})(\d{3})/, '$1 $2 $3');
  } else if (/^1[38]00\d{6}$/.test(cleanedText)) {
      // Long fancy: 1800 XXX XXX or 1300 XXX XXX
      return cleanedText.replace(/(1[38]00)(\d{3})(\d{3})/, '$1 $2 $3');
  }

  // If it doesn't match any format, return original input
  return text;
};